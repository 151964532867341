import { render, staticRenderFns } from "./calendar.vue?vue&type=template&id=9b1e2a3a&scoped=true&"
import script from "./calendar.vue?vue&type=script&lang=js&"
export * from "./calendar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b1e2a3a",
  null
  
)

export default component.exports