const __request = require(`./__request`);
/**
 * 授信账单
 */
export default function(val){
    var pParameter = {}
    pParameter.method = "POST"
    pParameter.urlSuffix = "/web/credit/creditBillList"
    pParameter.data = val
    return new Promise(function(resolve, reject){
        resolve(__request(pParameter))
    })
}
