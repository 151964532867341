//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import web_credit_creditBillDetail from "@/lib/data-service/default/web_credit_creditBillDetail";
import web_credit_creditBillRemark from "@/lib/data-service/default/web_credit_creditBillRemark";
export default {
  data() {
    return {
      expands: [],
      detail: []
    };
  },
  props: {
    tableData: {
      type: Array,
      default: []
    }
  },
  watch: {
    tableData(val) {
      if (val.length == 0) return;
      this.getDetail(val[0].id);
    }
  },
  methods: {
    remark(val) {
      this.$prompt("请输入备注信息", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: val.remark
      })
        .then(({ value }) => {
          web_credit_creditBillRemark({ billId: val.id, remark: value }).then(
            res => {
              res.code == this.SUCCESS_CODE
                ? this.$message({ type: "success", message: "修改成功！" })
                : "";
            }
          );
        })
        .catch(() => {});
    },
    checkboxInit(row) {
      const selectStatus = [1,3,4,6,7];
      if (selectStatus.includes(Number(row.billStatus))) {
        return true;
      } else {
        return false;
      }
    },
    handleSelectionChange(val) {
      let ids = val.map(item => {
        return item.id;
      });
      this.$emit("getIds", ids);
    },
    repayment(item) {
      this.$router.push({ name: "distributor-credit-back-payment" });
    },
    expanded(row, expandedRows) {
      this.expands = [];
      expandedRows.length > 0 ? (row ? this.expands.push(row.id) : "") : "";
      this.getDetail(row.id);
    },
    getDetail(id) {
      web_credit_creditBillDetail({ billId: id }).then(res => {
        this.detail = res.results;
      });
    },
    getRowKeys(row) {
      return row.id;
    }
  }
};
