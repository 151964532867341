import tableList from '../components/table'
import notBillTable from '../components/notBillTable'
import calendar from '../components/calendar'
import moment from 'moment';
import web_credit_creditBillList from '@/lib/data-service/default/web_credit_creditBillList'
import creditDetailList from '@/lib/data-service/default/web_credit_creditDetailList'
import scrollTop from '@/lib/common-service/scrollTop'

export default {
    data() {
        return {
            searchForm: {
                currentPage: 1,
                pageSize: 10,
                isGiveOut: 1
            },
            detail: {},
            tableData: [],
            billtable: [],
            activeName: 'first',
            navType: 0,
            day: null,
            dateShow: null,
            totalPage: 0,
            loading: false,
            initDay: '',
            Ids: ''
        }
    },
    components: { tableList, calendar, notBillTable },
    created() {
        let date = this.$route.query.date
        date ? this.initDay = date : this.search()
    },
    methods: {
        repayment() {
            if (this.Ids.length === 0) return this.$message({ type: "warning", message: "请勾选还款订单！" })
            this.$router.push({ name: 'distributor-credit-back-payment', query: { type: 2, ids: this.Ids } })
        },
        getIds(ids) {
            this.Ids = ids.join(',')
        },
        search(val) {

            this.loading = true
            this.activeName === 'first' ? this.getCreditBillList() : this.getCreditDetailList()
        },
        getCreditBillList() {
            let data = this.searchForm
            web_credit_creditBillList(data).then(res => {
                this.loading = false
                this.tableData = res.pageResult.pageData
                this.totalPage = res.pageResult.totalCount
                scrollTop(400)
            }, error => this.loading = false)
        },
        handleSizeChange(val) {
            this.searchForm.pageSize = val
            this.search()
        },
        handleCurrentChange(val) {
            this.searchForm.currentPage = val
            this.search()
        },
        setNav(val) {
            this.navType = val
            switch (val) {
                case 0:
                    this.searchForm.isOverdue = null
                    break;
                case 1:
                    this.searchForm.isOverdue = 3
                    break;
                case 2:
                    this.searchForm.isOverdue = 0
                    break;
                default:
                    break
            }
            this.search()
        },
        getTime(value) {
            let data = this.searchForm
            value ?
                (data.tradingHourStart = moment(value[0]).format(`YYYY-MM-DD`),
                    data.tradingHourEnd = moment(value[1]).format(`YYYY-MM-DD`)) :
                (data.tradingHourStart = '', data.tradingHourEnd = '', this.dateShow = null)
            this.search()
        },

        getCreditDetailList() {
            this.loading = true
            creditDetailList(this.searchForm).then(res => {
                this.loading = false
                this.billtable = res.pageResult.pageData.length === 0 ? [] : res.pageResult.pageData
                this.totalPage = res.pageResult.totalCount
                scrollTop(400)
            })
        },
        handleClick(val) {
            let data = this.searchForm
            val == "first" ? (data.isGiveOut = 1, this.search()) :
                (data.isGiveOut = 0, this.getCreditDetailList())

        }
    }
}
