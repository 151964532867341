//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';

export default {
    data(){
        return{
            dateTime:[
                
            ],
        }
    },
    props:{
        day:{
            type:Number,
            default:-1
        },
        initDay:{
            type:String,
            default:''
        }
    },
    mounted(){
        if(this.initDay){
            let initDay = JSON.parse(this.initDay)
            this.dateTime = [initDay[0],initDay[1]]
            this.changeDate(this.dateTime)
        }
    },
    watch:{
        day(val){
            this.setTime(val)
        },
    },
    methods:{
        changeDate(val){
            this.$emit('getTime',val)
        },
        setTime(val){
            let now,start,end
            let M = val||0
            now = moment()
            end = now.toDate()
            start = now.subtract(M, 'months').toDate()
            this.dateTime = [start,end]
            this.$emit('getTime',this.dateTime)
        }
    }
}
