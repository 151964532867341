//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "NotBillTable",
  data() {
    return {};
  },
  props: ["tableData"]
};
