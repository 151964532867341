const __request = require(`./__request`);
/**
 * 授信账单未出
 */
export default function (val) {
    var pParameter = {}
    pParameter.method = "POST"
    pParameter.urlSuffix = "/web/credit/creditDetailList",
        pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}
